import { createRouter, createWebHashHistory } from "vue-router";
import Login from "./pages/Login.vue";
import Signup from "./pages/Signup.vue";
import First from "./pages/First.vue";
import Friends from "./pages/Friends.vue";
import FriendsList from "./pages/friends/Friends.vue";
import WaitAcceptList from "./pages/friends/WaitAcceptList.vue";
import Search from "./pages/Search.vue";
import Profile from "./pages/Profile.vue";
import ChangePassword from "./pages/ChangePassword.vue";
import NotificationSettings from "./pages/NotificationSettings.vue";
import Chat from "./pages/Chat.vue";
import Terms from "./pages/Terms.vue";
import Policy from "./pages/Policy.vue";
import About from "./pages/About.vue";

const routes = [
  {
    name: "Home",
    path: "/",
    component: Friends,
    children: [
      {
        name: "FriendList",
        path: "",
        component: FriendsList,
        meta: { requiresAuth: true },
      },
      {
        name: "WaitAcceptList",
        path: "waitAccept",
        component: WaitAcceptList,
        meta: { requiresAuth: true },
      },
    ],
    meta: { requiresAuth: true },
  },
  {
    name: "Login",
    path: "/login",
    component: Login,
    meta: { requiresAuth: false },
  },
  {
    name: "Signup",
    path: "/signup",
    component: Signup,
    meta: { requiresAuth: false },
  },
  {
    name: "FirstSetup",
    path: "/first",
    component: First,
    meta: { requiresAuth: true },
  },
  {
    name: "Search",
    path: "/search",
    component: Search,
    meta: { requiresAuth: true },
  },
  {
    path: "/chat/:uid",
    component: Chat,
    name: "Chat",
    props: true,
    meta: { requiresAuth: true },
  },
  {
    name: "Profile",
    path: "/profile",
    component: Profile,
    meta: { requiresAuth: true },
  },
  {
    name: "ChangePassword",
    path: "/password",
    component: ChangePassword,
    meta: { requiresAuth: true },
  },
  {
    name: "NotificationSettings",
    path: "/notification",
    component: NotificationSettings,
    meta: { requiresAuth: true },
  },
  {
    name: "TermOfService",
    path: "/terms",
    component: Terms,
    meta: { requiresAuth: false },
  },
  {
    name: "PrivacyPolicy",
    path: "/policy",
    component: Policy,
    meta: { requiresAuth: false },
  },
  {
    name: "About",
    path: "/about",
    component: About,
    meta: { requiresAuth: false },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
