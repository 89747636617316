<template>
  <div>
    <div>
      <Header />
      <router-view v-show="isLoading"></router-view>
      <div v-show="!isLoading" uk-height-viewport="expand: true">
        <div>
          <div class="uk-overlay-default uk-position-cover"></div>
          <div class="uk-overlay uk-position-center">
            <img class="loadingImg" src="_assets/logo.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RouterView } from "vue-router";
import Header from "./components/Header.vue";
import { mapState } from "vuex";

export default {
  name: "App",
  components: { RouterView, Header },
  created() {
    this.$router.beforeEach((to, from, next) => {
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!this.isLogin) {
          next({
            path: "/login",
            query: { redirect: to.fullPath },
          });
        } else {
          next();
        }
      } else {
        next();
      }
    });
  },
  computed: mapState({
    isLoading: (state) => {
      return !Object.values(state.loadingStatus).reduce(
        (memo, isLoading) => memo || isLoading
      );
    },
    isLogin: (state) => state.auth.user !== null,
  }),
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;400;700&family=Nunito:wght@200;400;700&display=swap");
#app {
  font-family: Nunito, "Noto Sans JP", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}
h1,
.uk-h1,
h2,
.uk-h2,
h3,
.uk-h3,
h4,
.uk-h4,
h5,
.uk-h5,
h6,
.uk-h6,
.uk-heading-small,
.uk-heading-medium,
.uk-heading-large,
.uk-heading-xlarge,
.uk-heading-2xlarge {
  font-family: Nunito, "Noto Sans JP", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@keyframes blink {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}
.loadingImg {
  width: 20vw;
  animation-name: blink;
  animation-timing-function: ease-out;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-direction: alternate;
}
</style>
