<template>
  <div class="uk-position-relative">
    <div v-if="hasList && !isLoading">
      <a
        href="javascript:void(0)"
        @click="reload"
        class="uk-position-absolute uk-position-top-right uk-margin-top uk-margin-right uk-link-text uk-text-muted uk-text-large uk-position-z-index"
      >
        <i class="fas fa-sync-alt"></i>
      </a>
      <div uk-slideshow>
        <ul class="uk-slideshow-items" uk-height-viewport="offset-top: true">
          <li
            v-for="(profile, uid) in userList"
            :key="uid"
            :class="{
              man: profile.gender == 'man',
              woman: profile.gender == 'woman',
            }"
          >
            <div class="uk-card uk-card-body uk-height-1-1">
              <div class="uk-flex uk-flex-middle">
                <div class="icon uk-width-1-4"><img :src="profile.icon" /></div>
                <div class="name uk-width-3-4 uk-text-left uk-margin-left">
                  {{ profile.nickname }}
                </div>
              </div>
              <table class="uk-table uk-table-middle">
                <tr>
                  <td class="uk-text-muted uk-text-small uk-width-1-3">
                    キャラ
                  </td>
                  <td class="uk-text-left uk-width-2-3">
                    {{ profile.mainCharactor }}
                  </td>
                </tr>
                <tr>
                  <td class="uk-text-muted uk-text-small">ランク</td>
                  <td class="uk-text-left">{{ profile.myRank }}</td>
                </tr>
                <tr>
                  <td class="uk-text-muted uk-text-small">モード</td>
                  <td class="uk-text-left">{{ profile.playMode }}</td>
                </tr>
                <tr>
                  <td class="uk-text-muted uk-text-small">メッセージ</td>
                  <td class="uk-text-left">
                    <div class="profileMessage">{{ profile.message }}</div>
                  </td>
                </tr>
                <tr v-if="profile.voiceMessage">
                  <td class="uk-text-muted uk-text-small">ボイス</td>
                  <td class="uk-text-left">
                    <div>
                      <a @click="playVoiceMessage(uid)" class="uk-text-small">
                        <i class="fas fa-play"></i>&nbsp;再生する
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <div
                      class="requestRelationLink uk-text-center uk-positin-absolute uk-position-bottom-center"
                    >
                      <a
                        v-if="!profile.isRequested && !profile.isLoading"
                        @click="request(uid)"
                        class="uk-icon-button"
                        ><i class="fas fa-user-plus"></i
                      ></a>
                      <div v-if="profile.isLoading" uk-spinner></div>
                      <span v-if="profile.isRequested">リクエストしました</span>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </li>
        </ul>
        <div class="uk-position-bottom-center uk-position-small">
          <ul class="uk-dotnav">
            <li
              :uk-slideshow-item="index"
              v-for="(value, uid, index) in userList"
              :key="uid"
            >
              <a href="#"></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="!hasList && !isLoading">
      <div class="uk-position-relative" uk-height-viewport="offset-top: true">
        <img
          src="_assets/sad-tear-regular.svg"
          class="uk-width-1-3 uk-text-muted uk-positin-absolute uk-position-center"
          alt=""
          uk-img
        />
        <span class="uk-position-absolute uk-position-center uk-text-large">
          データがありません<br />アプリを広めてください<br />
          orz
        </span>
      </div>
    </div>
  </div>
</template>
<style scoped>
.profileMessage {
  white-space: pre-wrap;
}
.requestRelationLink {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 50px;
  height: 15vw;
}

.requestRelationLink .uk-icon-button {
  background: #90c4e9;
  color: #fff;
  font-size: 6vw;
  line-height: 1;
  border-radius: 50%;
  width: 15vw;
  height: 15vw;
}

.woman .requestRelationLink .uk-icon-button {
  background: #edb5d2;
}
</style>
<script>
import { mapActions, mapState } from "vuex";

export default {
  async created() {
    await this.$store.dispatch("listupUsers");
  },
  computed: mapState({
    userList: "userList",
    hasList: (state) => Object.keys(state.userList).length > 0,
    isLoading: "isLoading",
  }),
  methods: {
    async request(uid) {
      await this.$store.dispatch("requestRelation", uid);
    },
    ...mapActions({
      reload: "listupUsers",
    }),
  },
};
</script>
