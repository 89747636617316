<template>
  <div class="profileIcon" :class="$attrs.class" :data-src="src" uk-img></div>
</template>

<style scoped>
.profileIcon {
  border-radius: 50%;
  background-size: contain;
}
</style>

<script>
export default {
  name: "ProfileIcon",
  props: ["src"],
};
</script>
