<template lang="html">
  <div class="uk-container">
    <div class="uk-flex uk-flex-center uk-flex-middle" uk-height-viewport>
      <form class="uk-form-stacked uk-text-left" @submit="submit">
        <div class="uk-margin">
          <label class="uk-form-label">EMAIL</label>
          <div class="uk-inline">
            <span
              class="uk-form-icon uk-form-icon-flip"
              uk-icon="icon: mail"
            ></span>
            <input
              class="uk-input"
              type="text"
              placeholder="Email"
              v-model="mail"
              :class="{ 'uk-form-danger': mailHasError }"
            />
          </div>
          <div v-if="mailHasError" class="uk-alert-danger" uk-alert>
            {{ mailError }}
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label">PASSWORD</label>
          <div class="uk-inline">
            <span
              class="uk-form-icon uk-form-icon-flip"
              uk-icon="icon: lock"
            ></span>
            <input
              class="uk-input"
              type="password"
              placeholder="Passsord"
              v-model="password"
              :class="{ 'uk-form-danger': passwordHasError }"
            />
          </div>
          <div v-if="passwordHasError" class="uk-alert-danger" uk-alert>
            {{ passwordError }}
          </div>
        </div>
        <div class="uk-margin uk-text-center">
          <button class="uk-button uk-button-default" type="submit">
            登録する
          </button>
        </div>

        <div class="uk-margin">
          <router-link class="uk-link-text" to="/login"
            ><i class="fas fa-arrow-left"></i>&nbsp;ログイン</router-link
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { RouterLink } from "vue-router";
import Validator from "validatorjs";

const validateRules = {
  mail: ["required", "email"],
  password: ["required", { min: 8 }],
};

export default {
  data() {
    return {
      mail: "",
      password: "",
      mailError: "",
      passwordError: "",
      hasError: false,
    };
  },
  components: {
    RouterLink,
  },
  computed: {
    mailHasError() {
      return this.mailError !== "";
    },
    passwordHasError() {
      return this.passwordError !== "";
    },
  },
  methods: {
    submit(e) {
      e.preventDefault();
      const payload = { mail: this.mail, password: this.password };
      const validation = new Validator(payload, validateRules);
      if (validation.fails()) {
        if (validation.errors.has("mail")) {
          this.mailError = validation.errors.first("mail");
        }
        if (validation.errors.has("password")) {
          this.passwordError = validation.errors.first("password");
        }
        return;
      }
      this.$store
        .dispatch("signup", payload)
        .then(() => {
          this.$router.push("/");
        })
        .catch(() => {
          this.hasError = true;
        });
    },
  },
};
</script>
