<template lang="html">
  <nav id="header" class="uk-navbar-container" uk-navbar>
    <div class="uk-navbar-left">
      <router-link class="uk-navbar-item uk-logo" to="/"
        ><img class="logo-img" src="_assets/logo.svg" /><span class="logo-text"
          >Apex Pairs</span
        ></router-link
      >
    </div>
    <div class="uk-navbar-right">
      <ul class="uk-navbar-nav icons">
        <!-- <li>
          <a href=""><i class="far fa-bell"></i></a>
        </li> -->
        <li>
          <a href="#"
            ><profile-icon v-if="isLogin" class="user-icon" :src="icon" />
            <i v-if="!isLogin" class="fas fa-ellipsis-v"></i>
          </a>
          <div
            @click="hideMenu"
            ref="acountMenuWrap"
            uk-dropdown="mode: click; pos: top-right; offset: -20"
          >
            <ul class="uk-nav uk-dropdown-nav">
              <li v-if="!isLogin">
                <router-link to="/login">ログイン</router-link>
              </li>
              <li v-if="isLogin">
                <router-link to="/profile">プロフィール</router-link>
              </li>
              <li v-if="isLogin">
                <router-link to="/password">パスワード</router-link>
              </li>
              <li v-if="isLogin">
                <router-link to="/notification">通知設定</router-link>
              </li>
              <li>
                <router-link to="/about">アプリについて</router-link>
              </li>
              <li><router-link to="/terms">利用規約</router-link></li>
              <li>
                <router-link to="/policy">プライバシーポリシー</router-link>
              </li>
              <li v-if="isLogin">
                <a class="uk-link-text" @click="logout">ログアウト</a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<style scoped>
.logo-img {
  width: 11vw;
}
.logo-text {
  font-size: 6.4vw;
}
.icons.uk-navbar-nav > li > a {
  font-size: 8vw;
}
.user-icon {
  width: 8vw;
  height: 8vw;
}
@media screen and (min-width: 1024px) {
  #header {
    padding: 1em;
  }
  .logo-img {
    width: 40px;
  }

  .logo-text {
    font-size: 30px;
    line-height: 1;
  }

  .icons.uk-navbar-nav > li > a {
    font-size: 16px;
  }

  .user-icon {
    width: 60px;
    height: 60px;
  }
}
</style>

<script>
import { mapState } from "vuex";
import ProfileIcon from "./ProfileIcon.vue";
import { RouterLink } from "vue-router";
import UIkit from "uikit";

export default {
  components: { ProfileIcon, RouterLink },
  computed: mapState({
    isLogin: (state) => state.auth.user != null,
    icon: (state) => state.profile.icon,
  }),
  methods: {
    openMenu(e) {
      e.preventDefault();
    },
    async logout() {
      await this.$store.dispatch("logout");
      this.$router.push("login");
    },
    hideMenu() {
      UIkit.dropdown(this.$refs.acountMenuWrap).hide(0);
    },
  },
};
</script>
