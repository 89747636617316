<template>
  <div class="uk-margin-bottom">
    <div class="mainv">
      <div class="mainv__inner">
        <h1 class="mainv__copy">理想のDUOで<br />APEXを楽しもう</h1>
      </div>
    </div>
    <div class="uk-container">
      <h1 class="uk-heading-medium"></h1>
    </div>
    <div class="uk-text-center uk-margin">
      性別・モード・ランクから<br class="uk-hidden@m" />理想の相手を見つけよう
    </div>

    <div class="uk-container">
      <h2 class="uk-text-center">Step 1</h2>
      <p class="uk-text-center">プロフィールを登録しよう</p>
      <div class="uk-slider-container-offset" uk-slider>
        <div
          class="uk-position-relative uk-visible-toggle uk-light"
          tabindex="-1"
        >
          <ul class="uk-slider-items uk-child-width-1-2@s uk-grid">
            <li>
              <div class="uk-card uk-card-default">
                <div class="uk-card-media-top">
                  <img
                    class="uk-width-1-1"
                    src="/_assets/profile-step1.png"
                    alt=""
                  />
                </div>
                <div class="uk-card-body">
                  <p class="uk-text-center">ニックネーム</p>
                </div>
              </div>
            </li>
            <li>
              <div class="uk-card uk-card-default">
                <div class="uk-card-media-top">
                  <img
                    class="uk-width-1-1"
                    src="/_assets/profile-step2.png"
                    alt=""
                  />
                </div>
                <div class="uk-card-body">
                  <p class="uk-text-center">アイコン</p>
                </div>
              </div>
            </li>
            <li>
              <div class="uk-card uk-card-default">
                <div class="uk-card-media-top">
                  <img
                    class="uk-width-1-1"
                    src="/_assets/profile-step3.png"
                    alt=""
                  />
                </div>
                <div class="uk-card-body">
                  <p class="uk-text-center">性別</p>
                </div>
              </div>
            </li>
            <li>
              <div class="uk-card uk-card-default">
                <div class="uk-card-media-top">
                  <img
                    class="uk-width-1-1"
                    src="/_assets/profile-step4.png"
                    alt=""
                  />
                </div>
                <div class="uk-card-body">
                  <p class="uk-text-center">メインキャラ</p>
                </div>
              </div>
            </li>
            <li>
              <div class="uk-card uk-card-default">
                <div class="uk-card-media-top">
                  <img
                    class="uk-width-1-1"
                    src="/_assets/profile-step5.png"
                    alt=""
                  />
                </div>
                <div class="uk-card-body">
                  <p class="uk-text-center">ランク</p>
                </div>
              </div>
            </li>
            <li>
              <div class="uk-card uk-card-default">
                <div class="uk-card-media-top">
                  <img
                    class="uk-width-1-1"
                    src="/_assets/profile-step6.png"
                    alt=""
                  />
                </div>
                <div class="uk-card-body">
                  <p class="uk-text-center">相手の性別</p>
                </div>
              </div>
            </li>
            <li>
              <div class="uk-card uk-card-default">
                <div class="uk-card-media-top">
                  <img
                    class="uk-width-1-1"
                    src="/_assets/profile-step7.png"
                    alt=""
                  />
                </div>
                <div class="uk-card-body">
                  <p class="uk-text-center">相手に希望するランク</p>
                </div>
              </div>
            </li>
            <li>
              <div class="uk-card uk-card-default">
                <div class="uk-card-media-top">
                  <img
                    class="uk-width-1-1"
                    src="/_assets/profile-step8.png"
                    alt=""
                  />
                </div>
                <div class="uk-card-body">
                  <p class="uk-text-center">良く遊ぶゲームモード</p>
                </div>
              </div>
            </li>
            <li>
              <div class="uk-card uk-card-default">
                <div class="uk-card-media-top">
                  <img
                    class="uk-width-1-1"
                    src="/_assets/profile-step9.png"
                    alt=""
                  />
                </div>
                <div class="uk-card-body">
                  <p class="uk-text-center">メッセージ</p>
                </div>
              </div>
            </li>
            <li>
              <div class="uk-card uk-card-default">
                <div class="uk-card-media-top">
                  <img
                    class="uk-width-1-1"
                    src="/_assets/profile-step10.png"
                    alt=""
                  />
                </div>
                <div class="uk-card-body">
                  <p class="uk-text-center">ボイスメッセージ</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
      </div>
      <!-- /step1 -->

      <h2 class="uk-text-center">Step 2</h2>
      <p class="uk-text-center">理想の相手を見つけよう</p>
      <div class="uk-slider-container-offset" uk-slider>
        <div
          class="uk-position-relative uk-visible-toggle uk-light"
          tabindex="-1"
        >
          <ul class="uk-slider-items uk-child-width-1-2@s uk-grid">
            <li>
              <div class="uk-card uk-card-default">
                <div class="uk-card-media-top">
                  <img
                    class="uk-width-1-1"
                    src="/_assets/profile-sample-1.png"
                    alt=""
                  />
                </div>
              </div>
            </li>
            <li>
              <div class="uk-card uk-card-default">
                <div class="uk-card-media-top">
                  <img
                    class="uk-width-1-1"
                    src="/_assets/profile-sample-2.png"
                    alt=""
                  />
                </div>
              </div>
            </li>
            <li>
              <div class="uk-card uk-card-default">
                <div class="uk-card-media-top">
                  <img
                    class="uk-width-1-1"
                    src="/_assets/profile-sample-3.png"
                    alt=""
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
        <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
      </div>
      <!-- /step2 -->

      <div class="uk-text-center uk-margin">
        アカウントを登録して<br class="uk-hidden@m" />理想の相手を見つけよう
      </div>

      <div class="uk-margin uk-text-center">
        <router-link class="uk-button uk-button-primary" to="/signup"
          >新規登録&nbsp;<i class="fas fa-edit"></i
        ></router-link>
      </div>

      <h2 class="uk-text-center">利用ルール</h2>
      <div>
        <router-link to="/terms">利用規約</router-link
        >をよく読み、マナーを守ってご利用ください。違反行為を行ったユーザーはアカウントの利用を停止します。
      </div>
    </div>
    <!-- /.container -->
  </div>
</template>

<style scoped>
.mainv {
  background-image: url("/_assets/main-bg-sp.jpg");
  background-size: cover;
  background-position: center 80%;
}
.mainv__inner {
  height: 300px;
  max-width: 1200px;
  position: relative;
}
.mainv__copy {
  position: absolute;
  top: 10vw;
  left: 10vw;
  font-size: 10vw;
  font-weight: 900;
  color: #3c3c3c;
}

@media screen and (min-width: 1024px) {
  .mainv {
    background-position: center 70%;
  }
  .mainv__inner {
    height: 80vw;
    max-height: 540px;
  }
  .mainv__copy {
    font-size: 80px;
    top: 20%;
    left: 10%;
  }
}
</style>

<script>
import { RouterLink } from "vue-router";
export default {
  components: { RouterLink },
};
</script>
