<template>
  <div
    class="uk-position-relative"
    v-if="!isLoading"
    uk-height-viewport="offset-top: tue"
  >
    <div class="uk-text-left back-link">
      <router-link to="/" class="uk-link-muted">
        <i class="fas fa-angle-left uk-padding-small"></i
        >{{ partnerProfile.nickname }}
      </router-link>
    </div>
    <div
      ref="chatWrap"
      class="chat-wrap uk-position-relative"
      uk-height-viewport="offset-top: tue"
    >
      <ul
        ref="chat-list"
        class="chat-list uk-position-absolute uk-position-top uk-list uk-list-divider uk-margin-remove-top"
      >
        <li
          v-for="(chat, id) in chatList"
          :key="id"
          class="uk-padding-small"
          :class="{
            isSend: chat.fromUid == myUid,
            isRecived: chat.toUid == myUid,
          }"
        >
          <div
            class="uk-grid-small uk-flex-middle"
            :class="{ 'uk-flex-row-reverse': chat.fromUid == myUid }"
            uk-grid
          >
            <div
              class="uk-width-auto uk-flex uk-flex-center uk-flex-column uk-flex-middle"
            >
              <div class="uk-margin-small-bottom">
                <profile-icon
                  v-if="chat.toUid == myUid"
                  :src="partnerProfile.icon"
                  class="user-icon"
                />
                <profile-icon
                  v-if="chat.fromUid == myUid"
                  :src="myProfile.icon"
                  class="user-icon"
                />
              </div>
              <div
                class="uk-width-auto last-time-stamp uk-text-meta"
                v-if="hasMessage"
                v-html="formatTimestamp(chat.timestamp)"
              ></div>
            </div>
            <div class="uk-width-expand">
              <div
                class="uk-flex uk-flex-middle uk-flex-nowrap"
                :class="{ 'uk-flex-row-reverse': chat.fromUid == myUid }"
                uk-grid
              >
                <div class="uk-width-expand uk-text-left">
                  <div class="uk-text-small" v-if="hasMessage">
                    {{ chat.message }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="comment-form uk-position-fixed uk-position-bottom">
      <div class="uk-grid-collapse" uk-grid>
        <div class="uk-width-expand uk-inline">
          <span class="uk-form-icon"><i class="far fa-paper-plane"></i></span>
          <input class="uk-input" v-model="message" :disabled="isSending" />
        </div>
        <div class="uk-width-1-4">
          <button
            class="uk-button uk-button-primary uk-width-100 uk-inline"
            :disabled="isSending"
            @click="send"
          >
            送信
            <div v-if="isSending" class="uk-overlay-default uk-position-cover">
              <div uk-spinner></div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.user-icon {
  width: 10vw;
  height: 10vw;
}
.last-time-stamp {
  font-size: 0.8em;
  line-height: 1;
}
.chat-wrap {
  overflow: scroll;
}
.chat-list {
  padding-bottom: 40px;
  transition: all 0.7s;
}

@media screen and (min-width: 1024px) {
  .back-link {
    display: none;
  }
  .comment-form {
    max-width: 70%;
    margin-left: auto;
  }
  .user-icon {
    width: 40px;
    height: 40px;
  }
}
</style>
<script>
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import { RouterLink } from "vue-router";
import ProfileIcon from "../components/ProfileIcon.vue";

export default {
  props: ["uid"],
  data: () => ({
    message: "",
    isSending: false,
  }),
  components: { RouterLink, ProfileIcon },
  computed: {
    ...mapState({
      isLoading: "loadingStatus.relation",
      myProfile: "profile",
      myUid: (state) => {
        return state.auth.user.uid;
      },
    }),
    ...mapGetters({
      hasList: "hasFriends",
      hasMessage: "hasLastRecivedMessage",
      getLastRecived: "getLastRecivedMessage",
      isLoading: "isLoading",
    }),
    chatList() {
      if (this.uid) {
        return this.$store.getters.getChatList(this.uid);
      }
      return [];
    },
    partnerProfile() {
      if (this.uid) {
        return this.$store.getters.getPartnerProfile(this.uid);
      }
      return {};
    },
    isSelectUid() {
      return this.uid !== undefined;
    },
  },
  watch: {
    chatList() {
      this.$nextTick(() => {
        this.scrollBottom();
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.scrollBottom();
    });
  },
  methods: {
    scrollBottom() {
      if (this.$refs.chatWrap) {
        this.$refs.chatWrap.scrollTop = Number.MAX_SAFE_INTEGER;
      }
    },
    formatTimestamp(chatTimestamp) {
      const timestamp = moment(chatTimestamp);
      const now = new moment();
      const duration = moment.duration(now.diff(timestamp));
      if (duration.as("hours") < 1) {
        const min =
          Math.floor(duration.as("minutes")) < 0
            ? 0
            : Math.floor(duration.as("minutes"));
        return `${min}min<br>ago`;
      }
      if (timestamp.format("YYYYMMDD") == now.format("YYYYMMDD")) {
        return timestamp.format("H:m");
      }
      if (navigator.language.substr(0, 2) == "ja") {
        return timestamp.format("M月D日");
      }
      return timestamp.format("MMM D");
    },
    async send() {
      if (this.message == "") {
        return;
      }
      this.isSending = true;
      await this.$store.dispatch("sendMessage", {
        toUid: this.uid,
        message: this.message,
      });
      this.message = "";
      this.isSending = false;
    },
  },
};
</script>
