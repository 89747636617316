import { createStore } from "vuex";
import { initializeApp } from "firebase/app";
import {
  onAuthStateChanged,
  signInWithCredential,
  signInWithPopup,
  getAuth,
  TwitterAuthProvider,
  signOut,
  createUserWithEmailAndPassword,
  setPersistence,
  indexedDBLocalPersistence,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import {
  getDatabase,
  ref,
  set,
  // push,
  get,
  // serverTimestamp,
  query,
  equalTo,
  onValue,
  onChildAdded,
  off,
  orderByChild,
} from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getBlob,
} from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import _ from "underscore";

const firebaseConfig = {
  apiKey: "AIzaSyCp4Ab8-QblG7xpAfXIntTPJa0zHbDiQac",
  authDomain: "apexpairs.firebaseapp.com",
  databaseURL: "https://apexpairs-default-rtdb.firebaseio.com",
  projectId: "apexpairs",
  storageBucket: "apexpairs.appspot.com",
  messagingSenderId: "781433421301",
  appId: "1:781433421301:web:c98eec2aec0ac2174334bc",
  measurementId: "G-DK2M2T1ZKC",
};

initializeApp(firebaseConfig);

const twitterAuthProvider = new TwitterAuthProvider();
const db = getDatabase();
const auth = getAuth();
const storage = getStorage();
const functions = getFunctions();

const func = {
  listupUsers: httpsCallable(functions, "listupUsers"),
  requestRelation: httpsCallable(functions, "requestRelation"),
  acceptRelation: httpsCallable(functions, "acceptRelation"),
  rejectRelation: httpsCallable(functions, "rejectRelation"),
  deleteRelation: httpsCallable(functions, "deleteRelation"),
  sendMessage: httpsCallable(functions, "sendMessage"),
  deleteUser: httpsCallable(functions, "deleteUser"),
};

export const AuthState = {
  WAITING: 0,
  LOGEDIN: 1,
  LOGOUT: 2,
  ERROR: 3,
};

export const RelationStatus = {
  REQUESTING: "requesting",
  RECIVED: "recived",
  ACCEPT: "accept",
  REJECT: "reject",
  DELETED: "deleted",
};

const defaultProfile = {
  nickname: "NO NAME",
  icon: "_assets/icon_placeholder.svg",
  gender: "other",
  mainCharactor: "Ash",
  myRank: "Bronze",
  partnerRank: "Bronze",
  partnerGender: "any",
  playMode: "rank",
  message: "",
  voiceMessage: false,
};

const state = {
  firstAuthLoaded: false,
  loadingStatus: {
    auth: true,
    profile: true,
    relation: true,
    chat: true,
    readChat: true,
    search: false,
  },
  system: {
    mailActivated: false,
    firstSetuped: false,
  },
  auth: {
    authStateLoaded: false,
    state: AuthState.LOGOUT,
    user: null,
    credential: null,
  },
  profile: defaultProfile,
  relations: {},
  chats: {},
  readChats: [],
  userList: {},
  relationUserProfiles: {},
};

const mutations = {
  setAuthStateLoaded(state) {
    state.auth.authStateLoaded = true;
  },
  setFirstSetuped(state) {
    state.system.firstSetuped = true;
  },
  setMailActivated(state) {
    state.system.mailActivated = true;
  },
  setCredential(state, credential) {
    state.auth.credential = credential;
  },
  setProfile(state, profile) {
    state.profile = profile;
  },
  addRelation(state, payload) {
    state.relations[payload.key] = payload.body;
  },
  deleteRelation(state, key) {
    delete state.relations[key];
  },
  addMessage(state, payload) {
    state.chats[payload.key] = payload.body;
  },
  deleteMessage(state, key) {
    delete state.chats[key];
  },
  setAuthState(state, authState) {
    state.auth.state = authState;
  },
  setUser(state, user) {
    state.auth.user = user;
  },
  clearData(state) {
    state.profile = defaultProfile;
    state.auth = {
      state: AuthState.LOGOUT,
      user: null,
    };
    state.chats = {};
  },
  setLoadingState(state, isLoading) {
    state.isLoading = isLoading;
  },
  setAuthLoadingStatus(state, isLoading) {
    state.loadingStatus.auth = isLoading;
  },
  setSearchLoadingStatus(state, isLoading) {
    state.loadingStatus.search = isLoading;
  },
  setProfileLoadingStatus(state, isLoading) {
    state.loadingStatus.profile = isLoading;
  },
  setRelationLoadingStatus(state, isLoading) {
    state.loadingStatus.relation = isLoading;
  },
  setChatLoadingStatus(state, isLoading) {
    state.loadingStatus.chat = isLoading;
  },
  setReadChatLoadingStatus(state, isLoading) {
    state.loadingStatus.readChat = isLoading;
  },
  setFirstAuthLoadState(state, isLoaded) {
    state.firstAuthLoaded = isLoaded;
  },
  setUserList(state, users) {
    state.userList = _.extend(state.userList, users);
  },
  setUserListLoadingState(state, payload) {
    state.userList[payload.uid].isLoading = payload.isLoading;
  },
  setUserListRequestState(state, payload) {
    state.userList[payload.uid].isRequested = payload.isRequested;
  },
  setRelationUserProfile(state, payload) {
    state.relationUserProfiles[payload.uid] = payload.profile;
  },
  setRelationUserLoadingState(state, payload) {
    state.relationUserProfiles[payload.uid].isLoading = payload.isLoading;
  },
  updateRelationStatus(state, payload) {
    state.relations[payload.key] = payload.status;
  },
  setReadChat(state, payload) {
    state.readChat = payload;
  },
};

const actions = {
  async signup({ commit }, payload) {
    commit("setAuthState", AuthState.WAITING);

    try {
      const credential = await createUserWithEmailAndPassword(
        auth,
        payload.mail,
        payload.password
      );
      commit("setAuthState", AuthState.LOGEDIN);
      commit("setUser", credential.user);
    } catch (e) {
      commit("setAuthState", AuthState.ERROR);
      throw e;
    }
  },
  async passwordLogin({ commit }, payload) {
    commit("setAuthState", AuthState.WAITING);

    try {
      await setPersistence(auth, indexedDBLocalPersistence);
      const credential = EmailAuthProvider.credential(
        payload.mail,
        payload.password
      );
      await signInWithCredential(auth, credential);
      commit("setCredential", credential);
    } catch (e) {
      commit("setAuthState", AuthState.ERROR);
      throw e;
    }
  },
  async twitterLogin({ commit }) {
    try {
      await setPersistence(auth, indexedDBLocalPersistence);
      await signInWithPopup(auth, twitterAuthProvider);
    } catch (e) {
      commit("setAuthState", AuthState.ERROR);
      throw e;
    }
  },
  async logout({ commit }) {
    try {
      await signOut(auth);
      commit("setAuthState", AuthState.LOGOUT);
      commit("setUser", null);
    } catch (e) {
      console.error(e);
      commit("setAuthState", AuthState.LOGOUT);
      commit("setUser", null);
    }
  },

  async updateFirstActivated({ state, commit }) {
    const path = `system/${state.auth.user.uid}/firstSetuped`;
    await set(ref(db, path), true);
    commit("setFirstSetuped");
  },

  async updateProfile({ state, commit }, payload) {
    if (payload.icon && payload.icon.file) {
      const iconRef = storageRef(storage, `${state.auth.user.uid}/icon`);
      await uploadBytes(iconRef, payload.icon.file);
      payload.icon = URL.createObjectURL(payload.icon.file);
    } else if (payload.icon && payload.icon.url) {
      payload.icon = payload.icon.url;
    } else {
      payload.icon = "_assets/icon_placeholder.svg";
    }
    if (payload.voiceMessage && payload.voiceMessage.blob) {
      const voiceRef = storageRef(storage, `${state.auth.user.uid}/voice`);
      await uploadBytes(voiceRef, payload.voiceMessage.blob);
      payload.voiceMessage = URL.createObjectURL(payload.voiceMessage.blob);
    } else if (payload.voiceMessage && payload.voiceMessage.url) {
      payload.voiceMessage = payload.voiceMessage.url;
    } else {
      payload.voiceMessage = false;
    }
    let path = `profiles/${state.auth.user.uid}`;
    await set(
      ref(db, path),
      Object.assign({}, payload, {
        icon: !(payload.icon == "_assets/icon_placeholder.svg"),
        voiceMessage: !(payload.voiceMessage == false),
      })
    );
    path = `system/${state.auth.user.uid}/firstSetuped`;
    await set(ref(db, path), true);
    commit("setProfile", payload);
    commit("setFirstSetuped");
  },

  async sendMessage({ state }, payload) {
    const body = {
      fromUid: state.auth.user.uid,
      toUid: payload.toUid,
      message: payload.message,
    };
    await func.sendMessage(body);
  },

  async listupUsers({ commit }) {
    commit("setSearchLoadingStatus", true);
    const result = await func.listupUsers();
    const users = result.data;
    for (let uid in users) {
      const profile = users[uid];
      if (profile.icon) {
        const ref = storageRef(storage, `${uid}/icon`);
        const blob = await getBlob(ref);
        profile.icon = URL.createObjectURL(blob);
      } else {
        profile.icon = "_assets/icon_placeholder.svg";
      }
      if (profile.voiceMessage) {
        const ref = storageRef(storage, `${uid}/voice`);
        const blob = await getBlob(ref);
        profile.voice = URL.createObjectURL(blob);
      }
      profile.isRequested = false;
      profile.isLoading = false;
    }
    commit("setUserList", users);
    commit("setSearchLoadingStatus", false);
  },

  async requestRelation({ commit }, uid) {
    commit("setUserListLoadingState", { uid, isLoading: true });
    await func.requestRelation({
      toUid: uid,
    });
    commit("setUserListRequestState", { uid, isRequested: true });
    commit("setUserListLoadingState", { uid, isLoading: false });
  },
  async acceptRelation({ commit }, uid) {
    commit("setRelationUserLoadingState", { uid, isLoading: true });
    await func.acceptRelation({
      fromUid: uid,
    });
    commit("setRelationUserLoadingState", { uid, isLoading: false });
  },
  async rejectRelation({ commit }, uid) {
    commit("setRelationUserLoadingState", { uid, isLoading: true });
    await func.rejectRelation({
      fromUid: uid,
    });
    commit("setRelationUserLoadingState", { uid, isLoading: false });
  },
  async deleteUser({ commit }) {
    await func.deleteUser();
    commit("setUser", null);
  },
  async changePassword({ state, commit }, newPassword) {
    const credential = await reauthenticateWithCredential(
      state.auth.user,
      state.auth.credential
    );
    commit("setCredential", credential);
    await updatePassword(state.auth.user, newPassword);
  },
  async readChat({ state }, payload) {
    const readChatRef = ref(db, `readChats/${state.auth.user.uid}`);
    await set(readChatRef, _.uniq(state.readChats.concat(payload)));
  },
};

const getters = {
  waitAcceptList(state) {
    if (state.auth.user == null) {
      return {};
    }
    const list = _.chain(state.relations)
      .keys()
      .filter((uid) => state.relations[uid] == RelationStatus.RECIVED)
      .reject((uid) => {
        const exists = state.relationUserProfiles[uid] == undefined;
        return exists;
      })
      .value();

    const reduced = {};
    for (let uid of list) {
      reduced[uid] = state.relationUserProfiles[uid];
    }
    return reduced;
  },
  hasWaitAccept(state, getters) {
    return _.keys(getters.waitAcceptList).length > 0;
  },
  friendList(state) {
    if (state.auth.user == null) {
      return {};
    }
    const list = _.chain(state.relations)
      .keys()
      .filter((uid) => state.relations[uid] == RelationStatus.ACCEPT)
      .reject((uid) => {
        const exists = state.relationUserProfiles[uid] == undefined;
        return exists;
      })
      .value();

    const reduced = {};
    for (let uid of list) {
      reduced[uid] = state.relationUserProfiles[uid];
    }
    return reduced;
  },
  hasFriends(state, getters) {
    return _.keys(getters.friendList).length > 0;
  },
  getRelationStatus: (state) => (uid) => {
    return state.relations[uid];
  },
  getChatList: (state) => (uid) => {
    return _.chain(state.chats)
      .values()
      .filter((chat) => chat.fromUid == uid || chat.toUid == uid)
      .sortBy("timestamp")
      .value();
  },
  getLastRecivedMessage: (state) => (uid) => {
    return _.chain(state.chats)
      .values()
      .filter((chat) => chat.fromUid == uid)
      .sortBy("timestamp")
      .last()
      .value();
  },
  hasLastRecivedMessage: (state, getters) => (uid) => {
    return getters.getLastRecivedMessage(uid) != undefined;
  },
  getPartnerProfile: (state) => (uid) => {
    return state.relationUserProfiles[uid];
  },
  isLoading: (state) => {
    return Object.values(state.loadingStatus).reduce(
      (memo, isLoading) => memo || isLoading
    );
  },
};

const store = createStore({
  state,
  mutations,
  actions,
  getters,
});

const queries = [];

const listenProfile = (store, uid) => {
  const q = ref(db, `profiles/${uid}`);

  onValue(q, async (snapshot) => {
    if (!snapshot.exists()) {
      store.commit("setProfileLoadingStatus", false);
      return;
    }
    const value = snapshot.val();

    let icon = null;
    if (value.icon) {
      const ref = storageRef(storage, `${uid}/icon`);
      const data = await getBlob(ref);
      icon = URL.createObjectURL(data);
    } else {
      icon = "_assets/icon_placeholder.svg";
    }
    let voiceMessage = null;
    if (value.voiceMessage) {
      const ref = storageRef(storage, `${uid}/voice`);
      const data = await getBlob(ref);
      voiceMessage = URL.createObjectURL(data);
    } else {
      voiceMessage = false;
    }

    store.commit("setProfile", {
      nickname: value.nickname,
      icon: icon,
      gender: value.gender,
      mainCharactor: value.mainCharactor,
      myRank: value.myRank,
      partnerRank: value.partnerRank,
      partnerGender: value.partnerGender,
      playMode: value.playMode,
      message: value.message,
      voiceMessage: voiceMessage,
    });

    store.commit("setProfileLoadingStatus", false);
  });
  return q;
};

const listenRelations = async (store, uid) => {
  const relationsRef = ref(db, `relations/${uid}`);
  const relations = await get(relationsRef);
  if (!relations.hasChildren()) {
    store.commit("setRelationLoadingStatus", false);
  }
  onChildAdded(relationsRef, async (snapshot) => {
    const partnerUid = snapshot.key;
    const childRef = ref(db, `relations/${uid}/${partnerUid}`);
    if (!snapshot.exists()) {
      return;
    }
    const status = snapshot.val();

    const profileRef = ref(db, `profiles/${partnerUid}`);
    const relationUserProfile = await get(profileRef);
    if (!relationUserProfile.exists()) {
      return;
    }
    const value = relationUserProfile.val();

    let icon = null;
    if (value.icon) {
      const ref = storageRef(storage, `${uid}/icon`);
      const data = await getBlob(ref);
      icon = URL.createObjectURL(data);
    } else {
      icon = "_assets/icon_placeholder.svg";
    }
    let voiceMessage = null;
    if (value.voiceMessage) {
      const ref = storageRef(storage, `${uid}/voice`);
      const data = await getBlob(ref);
      voiceMessage = URL.createObjectURL(data);
    }

    store.commit("setRelationUserProfile", {
      uid: partnerUid,
      profile: {
        nickname: value.nickname,
        icon: icon,
        gender: value.gender,
        mainCharactor: value.mainCharactor,
        myRank: value.myRank,
        partnerRank: value.partnerRank,
        partnerGender: value.partnerGender,
        playMode: value.playMode,
        message: value.message,
        voiceMessage: voiceMessage,
        isLoading: false,
      },
    });

    store.commit("addRelation", {
      key: partnerUid,
      body: status,
    });

    store.commit("setRelationLoadingStatus", false);

    onValue(childRef, async (snapshot) => {
      store.commit("updateRelationStatus", {
        key: snapshot.key,
        status: snapshot.val(),
      });
    });
  });
  return relationsRef;
};

const listenChat = async (store, uid, fromto) => {
  const chatRef = ref(db, "chats");
  const q = query(chatRef, orderByChild(`${fromto}Uid`), equalTo(uid));
  const snapshot = await get(q);
  if (!snapshot.hasChildren()) {
    store.commit("setChatLoadingStatus", false);
  }
  onChildAdded(q, (snapshot) => {
    store.commit("addMessage", {
      key: snapshot.key,
      body: snapshot.val(),
    });
    store.commit("setChatLoadingStatus", false);
  });
  return q;
};
const listenChatTo = async (store, uid) => {
  return await listenChat(store, uid, "to");
};
const listenChatFrom = async (store, uid) => {
  return await listenChat(store, uid, "from");
};

const listenReadChat = async (store, uid) => {
  const chatRef = ref(db, `readChats/${uid}`);
  const snapshot = await get(chatRef);
  if (!snapshot.hasChildren()) {
    store.commit("setReadChatLoadingStatus", false);
  }
  onValue(chatRef, (snapshot) => {
    if (snapshot.exists()) {
      store.commit("setReadChat", snapshot.val());
    }
    store.commit("setReadChatLoadingStatus", false);
  });
  return chatRef;
};

onAuthStateChanged(auth, async (auth) => {
  if (auth) {
    store.commit("setAuthStateLoaded");
    store.commit("setAuthState", AuthState.LOGEDIN);
    store.commit("setUser", auth);
    store.commit("setProfileLoadingStatus", true);
    store.commit("setRelationLoadingStatus", true);
    store.commit("setChatLoadingStatus", true);
    const firstSetuped = await get(ref(db, `system/${auth.uid}/firstSetuped`));
    if (firstSetuped.exists() && firstSetuped.val()) {
      store.commit("setFirstSetuped");
    }
    queries.push(listenProfile(store, auth.uid));
    queries.push(await listenRelations(store, auth.uid));
    queries.push(await listenChatTo(store, auth.uid));
    queries.push(await listenChatFrom(store, auth.uid));
    queries.push(await listenReadChat(store, auth.uid));
  } else {
    store.commit("setAuthStateLoaded");
    store.commit("setAuthState", AuthState.LOGOUT);
    store.commit("setProfileLoadingStatus", false);
    store.commit("setRelationLoadingStatus", false);
    store.commit("setChatLoadingStatus", false);
    store.commit("setReadChatLoadingStatus", false);
    store.commit("setUser", null);
    queries.forEach((query) => off(query));
    queries.splice(0);
  }
  store.commit("setAuthLoadingStatus", false);
});

export default store;
