<template lang="html">
  <div class="uk-margin-top uk-margin-bottom">
    <form class="uk-form-stacked uk-container">
      <div class="uk-margin">
        <label class="uk-form-label">パスワード</label>
        <div class="uk-form-controls">
          <input
            class="uk-input"
            type="password"
            placeholder="Passowrd"
            v-model="password"
          />
        </div>
      </div>
      <div class="uk-margin">
        <label class="uk-form-label">確認</label>
        <div class="uk-form-controls">
          <input
            class="uk-input"
            type="password"
            placeholder="Confirm"
            v-model="confirm"
          />
        </div>
      </div>
      <div
        class="uk-position-relative uk-padding uk-margin-top uk-flex uk-flex-center"
      >
        <a
          @click="save"
          class="save-button uk-button uk-button-primary uk-inline"
          :disabled="loading"
          >変更する
          <div v-if="loading" class="uk-overlay-default uk-position-cover">
            <div uk-spinner></div>
          </div>
        </a>
      </div>
    </form>
  </div>
</template>

<style scoped></style>

<script>
import UIkit from "uikit";
import Validator from "validatorjs";

const validateRules = {
  password: ["required", { min: 8 }],
};

export default {
  data() {
    return {
      password: "",
      confirm: "",
      passwordError: "",
      loading: false,
    };
  },
  created() {},
  computed: {},
  methods: {
    async save(e) {
      e.preventDefault();
      const payload = { password: this.password };
      const validation = new Validator(payload, validateRules);
      if (validation.fails()) {
        if (validation.errors.has("password")) {
          this.passwordError = validation.errors.first("password");
        }
        if (this.password != this.confirm) {
          this.passwordError =
            "Doese not same the password and the confirm password";
        }
        return;
      }
      this.$store
        .dispatch("changePassword", this.passwordError)
        .then(() => {
          UIkit.notification({
            message: "保存パスワードを更新しました",
            status: "success",
            pos: "bottom-right",
            timeout: 2000,
          });
          this.$router.push("/");
        })
        .catch((e) => {
          console.error(e);
          UIkit.notification({
            message: "変更に失敗しました",
            status: "danger",
            pos: "bottom-right",
            timeout: 2000,
          });
          this.hasError = true;
        });
    },
  },
};
</script>
