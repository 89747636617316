<template>
  <div>
    <div>
      <router-link v-if="isCurrentFriends" class="find-user-icon" to="search"
        ><i class="fas fa-user-plus"></i
      ></router-link>
      <ul class="uk-margin-remove-top uk-margin-remove-bottom uk-tab" uk-tab>
        <li :class="{ 'uk-active': isCurrentFriends }">
          <router-link to="/">友達</router-link>
        </li>
        <li :class="{ 'uk-active': !isCurrentFriends }">
          <router-link to="/waitAccept">保留中</router-link>
        </li>
      </ul>
      <transition>
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition>
    </div>
  </div>
</template>

<style scoped>
.find-user-icon {
  font-size: 6vw;
  line-height: 1;
  border-radius: 50%;
  background: #90c4e9;
  color: #fff;
  width: 15vw;
  height: 15vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  position: absolute;
  right: 20px;
  bottom: 20px;
}
@media screen and (min-width: 1024px) {
  .find-user-icon {
    font-size: 40px;
    width: 80px;
    height: 80px;
    right: auto;
    left: 20px;
  }
}
</style>

<script>
import { RouterLink, RouterView } from "vue-router";

export default {
  components: { RouterLink, RouterView },
  computed: {
    isCurrentFriends() {
      return this.$route.name.match("FriendList");
    },
  },
};
</script>
