<template lang="html">
  <div class="uk-container">
    <div
      class="uk-flex uk-flex-center uk-flex-middle"
      uk-height-viewport="offset-top: true;"
    >
      <form class="uk-form-stacked uk-text-left" @submit="submit">
        <div class="uk-margin">
          <label class="uk-form-label">EMAIL</label>
          <div class="uk-inline">
            <span
              class="uk-form-icon uk-form-icon-flip"
              uk-icon="icon: mail"
            ></span>
            <input
              class="uk-input"
              type="text"
              placeholder="Email"
              v-model="mail"
              :class="{ 'uk-form-danger': mailHasError }"
            />
          </div>
          <div v-if="mailHasError" class="uk-alert-danger" uk-alert>
            {{ mailError }}
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label">PASSWORD</label>
          <div class="uk-inline">
            <span
              class="uk-form-icon uk-form-icon-flip"
              uk-icon="icon: lock"
            ></span>
            <input
              class="uk-input"
              type="password"
              placeholder="Passsord"
              v-model="password"
              :class="{ 'uk-form-danger': passwordHasError }"
            />
          </div>
          <div v-if="passwordHasError" class="uk-alert-danger" uk-alert>
            {{ passwordError }}
          </div>
        </div>
        <div class="uk-margin uk-text-center">
          <button type="submit" class="uk-button uk-button-default">
            ログイン
          </button>
        </div>

        <div class="uk-margin uk-text-right">
          <router-link class="uk-link-text" to="/signup"
            >新規登録&nbsp;<i class="fas fa-arrow-right"></i
          ></router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { RouterLink } from "vue-router";
import Validator from "validatorjs";
import UIkit from "uikit";

const validateRules = {
  mail: ["required"],
  password: ["required"],
};

export default {
  data() {
    return {
      mail: "",
      password: "",
      mailError: "",
      passwordError: "",
      hasError: false,
    };
  },
  components: {
    RouterLink,
  },
  computed: {
    mailHasError() {
      return this.mailError !== "";
    },
    passwordHasError() {
      return this.passwordError !== "";
    },
  },
  methods: {
    async submit(e) {
      e.preventDefault();
      const payload = { mail: this.mail, password: this.password };
      const validation = new Validator(payload, validateRules);
      if (validation.fails()) {
        if (validation.errors.has("mail")) {
          this.mailError = validation.errors.first("mail");
        }
        if (validation.errors.has("password")) {
          this.passwordError = validation.errors.first("password");
        }
        return;
      }
      try {
        await this.$store.dispatch("passwordLogin", payload);
        if (this.$store.state.system.firstSetuped) {
          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
          } else {
            this.$router.push("/");
          }
        } else {
          this.$router.push("/first");
        }
      } catch (e) {
        UIkit.notification("ログインに失敗しました。", {
          pos: "bottom-center",
          status: "danger",
        });
        this.hasError = true;
        console.error(e);
      }
    },
  },
};
</script>
