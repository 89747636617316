<template>
  <div class="uk-position-relative">
    <div v-if="hasList">
      <div uk-slideshow>
        <ul class="uk-slideshow-items" uk-height-viewport="offset-top: true">
          <li
            v-for="(profile, uid) in waitAcceptList"
            :key="uid"
            :class="{
              man: profile.gender == 'man',
              woman: profile.gender == 'woman',
            }"
          >
            <div class="uk-card uk-card-body uk-card-small uk-height-1-1">
              <div class="uk-flex uk-flex-middle">
                <div class="icon uk-width-1-4"><img :src="profile.icon" /></div>
                <div class="name uk-width-3-4 uk-text-left uk-margin-left">
                  {{ profile.nickname }}
                </div>
              </div>
              <table class="uk-table uk-table-small">
                <tr>
                  <td class="uk-text-muted uk-text-small uk-width-1-3">
                    キャラ
                  </td>
                  <td class="uk-text-left uk-width-2-3">
                    {{ profile.mainCharactor }}
                  </td>
                </tr>
                <tr>
                  <td class="uk-text-muted uk-text-small">ランク</td>
                  <td class="uk-text-left">{{ profile.myRank }}</td>
                </tr>
                <tr>
                  <td class="uk-text-muted uk-text-small">モード</td>
                  <td class="uk-text-left">{{ profile.playMode }}</td>
                </tr>
                <tr>
                  <td class="uk-text-muted uk-text-small">メッセージ</td>
                  <td class="uk-text-left">
                    <div class="profileMessage">{{ profile.message }}</div>
                  </td>
                </tr>
                <tr v-if="profile.voiceMessage">
                  <td class="uk-text-muted uk-text-small">ボイス</td>
                  <td class="uk-text-left">
                    <div>
                      <a @click="playVoiceMessage(uid)" class="uk-text-small">
                        <i class="fas fa-play"></i>&nbsp;再生する
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <div
                      class="actionBtnWrap uk-text-center uk-positin-absolute uk-position-bottom-center"
                      :style="{
                        'justify-content': actionBtnWrapJustifyContent(
                          uid,
                          profile.isLoading
                        ),
                      }"
                    >
                      <a
                        v-if="isRequesting(uid) && !profile.isLoading"
                        @click="reject(uid)"
                        class="rejectLink"
                        ><i class="far fa-times-circle"></i
                      ></a>
                      <a
                        v-if="isRequesting(uid) && !profile.isLoading"
                        @click="accept(uid)"
                        class="acceptLink"
                        ><i class="fas fa-user-plus"></i
                      ></a>

                      <div v-if="profile.isLoading" uk-spinner></div>
                      <span v-if="isAccepted(uid)">承認しました</span>
                      <span v-if="isRejected(uid)">拒否しました</span>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </li>
        </ul>
        <div class="uk-position-bottom-center uk-position-small">
          <ul class="uk-dotnav">
            <li
              :uk-slideshow-item="index"
              v-for="(value, uid, index) in waitAcceptList"
              :key="uid"
            >
              <a href="#"></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="!hasList">
      <div class="uk-position-relative" uk-height-viewport="offset-top: true">
        <img
          src="_assets/sad-tear-regular.svg"
          class="uk-width-1-3 uk-text-muted uk-positin-absolute uk-position-center"
          alt=""
          uk-img
        />
        <span class="uk-position-absolute uk-position-center uk-text-large">
          保留中のリクエストは<br />ありません。
        </span>
      </div>
    </div>
  </div>
</template>
<style scoped>
.profileMessage {
  white-space: pre-wrap;
}
.actionBtnWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 50px;
  height: 15vw;
  width: 33vw;
  justify-content: space-between;
}

.actionBtnWrap a {
  display: flex;
  width: 15vw;
  height: 15vw;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.actionBtnWrap a:hover {
  text-decoration: none;
}

.acceptLink {
  background: #90c4e9;
  color: #fff;
  font-size: 6vw;
  line-height: 1;
  border-radius: 50%;
}

.woman .acceptLink {
  background: #edb5d2;
}

.rejectLink {
  font-size: 15vw;
  color: #ff7474;
}
</style>
<script>
import { mapGetters, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      isLoading: "isLoading",
    }),
    ...mapGetters({
      waitAcceptList: "waitAcceptList",
      hasList: "hasWaitAccept",
      getRelationStatus: "getRelationStatus",
    }),
  },
  methods: {
    async accept(uid) {
      await this.$store.dispatch("acceptRelation", uid);
    },
    async reject(uid) {
      await this.$store.dispatch("rejectRelation", uid);
    },
    isRequesting(uid) {
      return this.getRelationStatus(uid) == "recived";
    },
    isAccepted(uid) {
      return this.getRelationStatus(uid) == "accept";
    },
    isRejected(uid) {
      return this.getRelationStatus(uid) == "reject";
    },
    actionBtnWrapJustifyContent(uid, isLoading) {
      return this.isAccepted(uid) || this.isRejected(uid) || isLoading
        ? "center"
        : "space-between";
    },
  },
};
</script>
