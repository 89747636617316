<template>
  <div class="uk-position-relative">
    <div v-if="hasList" class="column-wrap">
      <div class="friend-list uk-box-shadow-small">
        <ul class="uk-list uk-list-divider" uk-height-viewport>
          <li
            v-for="(profile, uid) in friendList"
            :key="uid"
            :class="{
              man: profile.gender == 'man',
              woman: profile.gender == 'woman',
            }"
            class="uk-padding-small"
          >
            <a @click="select(uid)" class="uk-link-text">
              <div class="uk-grid-small uk-flex-middle" uk-grid>
                <div class="uk-width-auto">
                  <img :src="profile.icon" class="user-icon" alt="" />
                </div>
                <div class="uk-width-expand">
                  <div class="uk-flex uk-flex-middle uk-flex-nowrap" uk-grid>
                    <div class="uk-width-expand uk-text-left">
                      <div class="">{{ profile.nickname }}</div>
                      <div class="uk-text-muted uk-text-meta" v-if="hasMessage">
                        {{ lastMessageFirstLine(uid) }}
                      </div>
                    </div>
                    <div
                      class="uk-width-auto last-time-stamp uk-text-meta"
                      v-if="hasMessage"
                      v-html="lastMessageTimestamp(uid)"
                    ></div>
                  </div>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div ref="chatPreview" class="chat-preview">
        <chat :uid="selectedUid" v-if="isUserSelected"></chat>
      </div>
    </div>

    <div v-if="!hasList">
      <div class="uk-position-relative" uk-height-viewport="offset-top: true">
        <span class="uk-position-absolute uk-position-center uk-text-large">
          一緒に遊ぶ仲間を<br />見つけよう！
        </span>
      </div>
    </div>
  </div>
</template>
<style scoped>
.user-icon {
  width: 10vw;
  height: 10vw;
}
.last-time-stamp {
  font-size: 0.8em;
  line-height: 1;
}

.chat-preview {
  display: none;
}
@media screen and (min-width: 1024px) {
  .column-wrap {
    display: flex;
  }
  .friend-list {
    width: 30%;
  }
  .chat-preview {
    display: block;
    width: 70%;
  }
  .user-icon {
    width: 40px;
    height: 40px;
  }
}
</style>
<script>
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import Chat from "../Chat.vue";

export default {
  data: () => ({ selectedUid: "" }),
  components: { Chat },
  computed: {
    ...mapState({
      isLoading: "loadingStatus.relation",
    }),
    ...mapGetters({
      friendList: "friendList",
      hasList: "hasFriends",
      hasMessage: "hasLastRecivedMessage",
      getLastRecived: "getLastRecivedMessage",
    }),
    isUserSelected() {
      return this.selectedUid != "";
    },
  },
  methods: {
    lastMessageFirstLine(uid) {
      if (!this.hasMessage(uid)) {
        return "";
      }
      const chat = this.getLastRecived(uid);
      return chat.message.substr(0, 25);
    },
    lastMessageTimestamp(uid) {
      if (!this.hasMessage(uid)) {
        return "";
      }
      const chat = this.getLastRecived(uid);
      const timestamp = moment(chat.timestamp);
      const now = new moment();
      const duration = moment.duration(now.diff(timestamp));
      if (duration.as("hours") < 1) {
        return `${Math.floor(duration.as("minutes"))}min<br>ago`;
      }
      if (timestamp.format("YYYYMMDD") == now.format("YYYYMMDD")) {
        return timestamp.format("H:m");
      }
      if (navigator.language.substr(0, 2) == "ja") {
        return timestamp.format("M月D日");
      }
      return timestamp.format("MMM D");
    },
    select(uid) {
      if (this.$refs.chatPreview.offsetParent == null) {
        this.$router.push({ name: "Chat", params: { uid } });
      } else {
        this.selectedUid = uid;
      }
    },
  },
};
</script>
