<template lang="html">
  <div class="uk-margin-top uk-margin-bottom">
    <form class="uk-form-stacked uk-container">
      <div class="uk-margin">
        <label class="uk-form-label">ニックネーム</label>
        <div class="uk-form-controls">
          <input
            class="uk-input"
            type="text"
            placeholder="nickname..."
            max-length="10"
            v-model="nickname"
          />
        </div>
      </div>
      <!-- ニックネーム -->

      <div class="uk-margin">
        <label class="uk-form-label">アイコン</label>
        <div uk-form-custom>
          <input type="file" accept="image/*" @change="changeIcon" />
          <button
            class="uk-button uk-button-default"
            type="button"
            tabindex="-1"
          >
            Select
          </button>
          <div v-if="isIconSelected" class="icon-preview">
            <img :src="icon.url" alt="" />
          </div>
          <div v-if="iconFileError" class="uk-alert-danger" uk-alert>
            <a class="uk-alert-close" uk-close></a>
            <p>{{ iconFileError }}</p>
          </div>
        </div>
      </div>
      <!-- アイコン -->

      <div class="uk-margin">
        <label class="uk-form-label">性別</label>
        <ul class="gender-selector uk-subnav uk-subnav-pill">
          <li :class="{ 'uk-active': gender === 'man' }">
            <a href="#" @click="changeGender($event, 'man')"
              ><img src="_assets/man.svg" alt=""
            /></a>
          </li>
          <li :class="{ 'uk-active': gender === 'woman' }">
            <a href="#" @click="changeGender($event, 'woman')"
              ><img src="_assets/woman.svg" alt=""
            /></a>
          </li>
        </ul>
        <ul class="uk-margin-top uk-subnav uk-flex-right uk-subnav-pill">
          <li :class="{ 'uk-active': gender === 'other' }">
            <a
              href="#"
              class="uk-link-text"
              @click="changeGender($event, 'other')"
              >設定しない</a
            >
          </li>
        </ul>
      </div>
      <!-- 性別 -->

      <div class="uk-margin">
        <label class="uk-form-label">メインキャラ</label>
        <ul class="uk-subnav uk-subnav-pill">
          <li
            :class="{
              'uk-active': mainCharactor === 'Bloodhound',
            }"
          >
            <a href="#" @click="changeMainCharactor($event, 'Bloodhound')"
              >ブラッドハウンド</a
            >
          </li>
          <li :class="{ 'uk-active': mainCharactor === 'Gibraltar' }">
            <a href="#" @click="changeMainCharactor($event, 'Gibraltar')"
              >ジブラルタル</a
            >
          </li>
          <li :class="{ 'uk-active': mainCharactor === 'Lifeline' }">
            <a href="#" @click="changeMainCharactor($event, 'Lifeline')"
              >ライフライン</a
            >
          </li>
          <li
            :class="{
              'uk-active': mainCharactor === 'Pathfinder',
            }"
          >
            <a href="#" @click="changeMainCharactor($event, 'Pathfinder')"
              >パスファインダー</a
            >
          </li>
          <li :class="{ 'uk-active': mainCharactor === 'Wraith' }">
            <a href="#" @click="changeMainCharactor($event, 'Wraith')"
              >レイス</a
            >
          </li>

          <li :class="{ 'uk-active': mainCharactor === 'Bangalore' }">
            <a href="#" @click="changeMainCharactor($event, 'Bangalore')"
              >バンガロール</a
            >
          </li>
          <li :class="{ 'uk-active': mainCharactor === 'Caustic' }">
            <a href="#" @click="changeMainCharactor($event, 'Caustic')"
              >コースティック</a
            >
          </li>
          <li :class="{ 'uk-active': mainCharactor === 'Mirage' }">
            <a href="#" @click="changeMainCharactor($event, 'Mirage')"
              >ミラージュ</a
            >
          </li>
          <li :class="{ 'uk-active': mainCharactor === 'Octane' }">
            <a href="#" @click="changeMainCharactor($event, 'Octane')"
              >オクタン</a
            >
          </li>
          <li :class="{ 'uk-active': mainCharactor === 'Wattson' }">
            <a href="#" @click="changeMainCharactor($event, 'Wattson')"
              >ワットソン</a
            >
          </li>
          <li :class="{ 'uk-active': mainCharactor === 'Crypto' }">
            <a href="#" @click="changeMainCharactor($event, 'Crypto')"
              >クリプト</a
            >
          </li>
          <li :class="{ 'uk-active': mainCharactor === 'Revenant' }">
            <a href="#" @click="changeMainCharactor($event, 'Revenant')"
              >レヴナント</a
            >
          </li>
          <li :class="{ 'uk-active': mainCharactor === 'Loba' }">
            <a href="#" @click="changeMainCharactor($event, 'Loba')">ローバ</a>
          </li>
          <li :class="{ 'uk-active': mainCharactor === 'Rampart' }">
            <a href="#" @click="changeMainCharactor($event, 'Rampart')"
              >ランパート</a
            >
          </li>
          <li :class="{ 'uk-active': mainCharactor === 'Horizon' }">
            <a href="#" @click="changeMainCharactor($event, 'Horizon')"
              >ホライゾン</a
            >
          </li>
          <li :class="{ 'uk-active': mainCharactor === 'Fuse' }">
            <a href="#" @click="changeMainCharactor($event, 'Fuse')"
              >ヒューズ</a
            >
          </li>
          <li :class="{ 'uk-active': mainCharactor === 'Valkyrie' }">
            <a href="#" @click="changeMainCharactor($event, 'Valkyrie')"
              >ヴァルキリー</a
            >
          </li>
          <li :class="{ 'uk-active': mainCharactor === 'Seer' }">
            <a href="#" @click="changeMainCharactor($event, 'Seer')">シア</a>
          </li>
          <li :class="{ 'uk-active': mainCharactor === 'Ash' }">
            <a href="#" @click="changeMainCharactor($event, 'Ash')">アッシュ</a>
          </li>
        </ul>
      </div>

      <!-- メインキャラ -->

      <div class="uk-margin">
        <label class="uk-form-label">ランク</label>
        <ul class="uk-subnav uk-subnav-pill">
          <li :class="{ 'uk-active': myRank === 'Bronze' }">
            <a href="#" @click="changeMyRank($event, 'Bronze')">ブロンズ</a>
          </li>
          <li :class="{ 'uk-active': myRank === 'Silver' }">
            <a href="#" @click="changeMyRank($event, 'Silver')">シルバー</a>
          </li>
          <li :class="{ 'uk-active': myRank === 'Gold' }">
            <a href="#" @click="changeMyRank($event, 'Gold')">ゴールド</a>
          </li>
          <li :class="{ 'uk-active': myRank === 'Platinum' }">
            <a href="#" @click="changeMyRank($event, 'Platinum')">プラチナ</a>
          </li>
          <li :class="{ 'uk-active': myRank === 'Diamond' }">
            <a href="#" @click="changeMyRank($event, 'Diamond')">ダイア</a>
          </li>
          <li :class="{ 'uk-active': myRank === 'Master' }">
            <a href="#" @click="changeMyRank($event, 'Master')">マスター</a>
          </li>
          <li :class="{ 'uk-active': myRank === 'Predator' }">
            <a href="#" @click="changeMyRank($event, 'Predator')">プレデター</a>
          </li>
        </ul>
      </div>
      <!-- ランク -->

      <div class="uk-margin">
        <label class="uk-form-label">相手の性別</label>
        <ul class="gender-selector uk-subnav uk-subnav-pill">
          <li :class="{ 'uk-active': partnerGender === 'man' }">
            <a href="#" @click="changePartnerGender($event, 'man')"
              ><img src="_assets/man.svg" alt=""
            /></a>
          </li>
          <li :class="{ 'uk-active': partnerGender === 'woman' }">
            <a href="#" @click="changePartnerGender($event, 'woman')"
              ><img src="_assets/woman.svg" alt=""
            /></a>
          </li>
        </ul>
        <ul class="uk-margin-top uk-subnav uk-flex-right uk-subnav-pill">
          <li :class="{ 'uk-active': partnerGender === 'any' }">
            <a
              href="#"
              class="uk-link-text"
              @click="changePartnerGender($event, 'other')"
              >設定しない</a
            >
          </li>
        </ul>
      </div>

      <!-- 相手の性別 -->

      <div class="uk-margin">
        <label class="uk-form-label">相手のランク</label>
        <ul uk-switcher class="uk-subnav uk-subnav-pill">
          <li :class="{ 'uk-active': partnerRank === 'Bronze' }">
            <a href="#" @click="changePartnerRank($event, 'Bronze')"
              >ブロンズ</a
            >
          </li>
          <li :class="{ 'uk-active': partnerRank === 'Silver' }">
            <a href="#" @click="changePartnerRank($event, 'Silver')"
              >シルバー</a
            >
          </li>
          <li :class="{ 'uk-active': partnerRank === 'Gold' }">
            <a href="#" @click="changePartnerRank($event, 'Gold')">ゴールド</a>
          </li>
          <li :class="{ 'uk-active': partnerRank === 'Platinum' }">
            <a href="#" @click="changePartnerRank($event, 'Platinum')"
              >プラチナ</a
            >
          </li>
          <li :class="{ 'uk-active': partnerRank === 'Diamond' }">
            <a href="#" @click="changePartnerRank($event, 'Diamond')">ダイア</a>
          </li>
          <li :class="{ 'uk-active': partnerRank === 'Master' }">
            <a href="#" @click="changePartnerRank($event, 'Master')"
              >マスター</a
            >
          </li>
          <li :class="{ 'uk-active': partnerRank === 'Predator' }">
            <a href="#" @click="changePartnerRank($event, 'Predator')"
              >プレデター</a
            >
          </li>
        </ul>
      </div>
      <!-- 相手のランク -->

      <div class="uk-margin">
        <label class="uk-form-label">ゲームモード</label>
        <ul uk-switcher class="uk-subnav uk-subnav-pill">
          <li :class="{ 'uk-active': partnerRank === 'casual' }">
            <a href="#" @click="changePlayMode($event, 'casual')">カジュアル</a>
          </li>
          <li :class="{ 'uk-active': partnerRank === 'rank' }">
            <a href="#" @click="changePlayMode($event, 'rank')">ランク</a>
          </li>
          <li :class="{ 'uk-active': partnerRank === 'arena' }">
            <a href="#" @click="changePlayMode($event, 'arena')">アリーナ</a>
          </li>
          <li :class="{ 'uk-active': partnerRank === 'arenaRank' }">
            <a href="#" @click="changePlayMode($event, 'arenaRank')"
              >アリーナランク</a
            >
          </li>
        </ul>
      </div>

      <!-- ゲームモード -->

      <div class="uk-margin">
        <label class="uk-form-label">メッセージ</label>
        <textarea
          class="uk-textarea uk-form-width-medium uk-width-1-1"
          placeholder="message..."
          max-length="50"
          v-model="message"
          rows="5"
        ></textarea>
      </div>

      <!-- メッセージ -->

      <div class="uk-margin">
        <label class="uk-form-label"
          >ボイスメッセージ<span class="uk-text-meta">(最大10秒)</span></label
        >
        <div class="uk-button-group">
          <button
            @click="startVoiceRecord"
            class="uk-button uk-button-default"
            :disabled="!isUserMediaSupported"
          >
            <i class="fas fa-microphone"></i>
          </button>
          <button
            @click="playVoiceMessage"
            class="uk-button uk-button-default"
            :disabled="!isVoiceRecorded"
          >
            <i class="fas fa-play"></i>
          </button>
          <button
            @click="deleteVoiceMessage"
            class="uk-button uk-button-default"
            :disabled="!isVoiceRecorded"
          >
            <i class="fas fa-trash-alt"></i>
          </button>
        </div>
      </div>
      <div
        ref="voiceRecordModal"
        id="voiceRecordModal"
        class="uk-modal-full"
        uk-modal="bg-close: false"
      >
        <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
          <div
            ref="voiceRecordModalCounter"
            class="uk-position-relative uk-visible-toggle uk-light voiceRecordModal__counter"
            tabindex="-1"
            uk-slideshow="animation: fade; draggable: false; index: 9;"
          >
            <ul class="uk-slideshow-items">
              <li><img src="_assets/count_1.png" alt="" uk-cover /></li>
              <li><img src="_assets/count_2.png" alt="" uk-cover /></li>
              <li><img src="_assets/count_3.png" alt="" uk-cover /></li>
              <li><img src="_assets/count_4.png" alt="" uk-cover /></li>
              <li><img src="_assets/count_5.png" alt="" uk-cover /></li>
              <li><img src="_assets/count_6.png" alt="" uk-cover /></li>
              <li><img src="_assets/count_7.png" alt="" uk-cover /></li>
              <li><img src="_assets/count_8.png" alt="" uk-cover /></li>
              <li><img src="_assets/count_9.png" alt="" uk-cover /></li>
              <li><img src="_assets/count_10.png" alt="" uk-cover /></li>
            </ul>
          </div>
          <div class="voiceRecordModal__stop">
            <button class="uk-button uk-button-link" @click="stopVoiceRecord">
              <i class="far fa-stop-circle"></i>
            </button>
          </div>
        </div>
        <!-- /.uk-modal-dialog -->
      </div>
      <!--/.voiceRecordModal -->
      <div v-if="voiceRecordError" class="uk-alert-danger" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p>{{ voiceRecordError }}</p>
      </div>
      <!-- ヴォイスメッセージ -->
    </form>

    <div
      class="uk-position-relative uk-padding uk-margin-top uk-flex uk-flex-between"
    >
      <a
        class="delete-button uk-button uk-button-danger uk-inline"
        :disabled="loading"
        href="#delete-confirm-modal"
        uk-toggle
        >削除
        <div v-if="loading" class="uk-overlay-default uk-position-cover">
          <div uk-spinner></div>
        </div>
      </a>
      <a
        @click="save"
        class="save-button uk-button uk-button-primary uk-inline"
        :disabled="loading"
        >完了
        <div v-if="loading" class="uk-overlay-default uk-position-cover">
          <div uk-spinner></div>
        </div>
      </a>
    </div>
    <!-- This is the modal -->
    <div ref="deleteModal" id="delete-confirm-modal" uk-modal>
      <div class="uk-modal-dialog">
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">削除しますか？</h2>
        </div>
        <div class="uk-modal-body">
          <p>
            データは復旧することが出来ません。本当に削除してよろしいですか？
          </p>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button
            class="uk-button uk-button-default uk-modal-close"
            type="button"
          >
            キャンセル
          </button>
          <button
            @click="deleteUser"
            class="uk-button uk-button-danger"
            type="button"
          >
            削除する
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.gender-selector {
  list-style: none;
}
.gender-selector li {
  width: 40%;
}
.profile-card-body {
  width: 90%;
}
.icon-preview {
  height: 30vw;
  overflow: hidden;
}
.icon-preview img {
  max-width: 100%;
  max-height: 100%;
}

#voiceRecordModal .uk-modal-body {
  background-color: rgba(256, 256, 256, 0.8);
}
.voiceRecordModal__stop {
  text-align: center;
}
.voiceRecordModal__stop i {
  color: #ff0000c7;
  font-size: 14vw;
}
</style>

<script>
import UIkit from "uikit";
import { mapState } from "vuex";

export default {
  data() {
    return {
      nickname: "",
      icon: null,
      iconFileError: "",
      gender: "other",
      mainCharactor: "Bloodhound",
      myRank: "Bronze",
      partnerRank: "Bronze",
      partnerGender: "any",
      playMode: "rank",
      message: "",
      voiceMessage: null,
      voiceRecordCounter: 10,
      voiceRecordError: "",
      mediaRecorder: null,
      voiceRecordChunk: [],
      voiceRecordTimerId: -1,
      loading: false,
    };
  },
  created() {
    // preload counter images
    for (let i = 1; i <= 10; i++) {
      const img = new Image();
      img.src = `_assets/count_${i}.png`;
    }

    // load stored profile
    this.nickname = this.profile.nickname;
    this.icon = {
      url: this.profile.icon,
    };
    this.gender = this.profile.gender;
    this.mainCharactor = this.profile.mainCharactor;
    this.myRank = this.profile.myRank;
    this.partnerRank = this.profile.partnerRank;
    this.partnerGender = this.profile.partnerGender;
    this.playMode = this.profile.playMode;
    this.message = this.profile.message;
    this.voiceMessage = {
      url: this.profile.message,
    };
  },
  computed: {
    isIconSelected() {
      return this.icon !== null;
    },
    isVoiceRecorded() {
      return this.voiceMessage !== null;
    },
    isUserMediaSupported() {
      return navigator.mediaDevices && navigator.mediaDevices.getUserMedia;
    },
    ...mapState({
      profile: "profile",
    }),
  },
  methods: {
    changeIcon(event) {
      const files = event.currentTarget.files;
      if (files.length == 0) {
        return;
      }
      const file = files[0];

      if (file.size > 3 * 1024 * 1024) {
        this.iconFileError =
          "ファイルサイズが大きすぎます。3Mb以内でお願いします";
      }

      if (!/^image\//.test(file.type)) {
        this.iconFileError = "画像ファイルを選んでください";
      }

      this.icon = {
        file: file,
        url: URL.createObjectURL(file),
      };
    },
    slideNext() {
      this.slider.show(this.slider.index + 1);
    },
    changeGender(event, gender) {
      event.preventDefault();
      this.gender = gender;
    },
    changeMainCharactor(event, mainCharactor) {
      event.preventDefault();
      this.mainCharactor = mainCharactor;
    },
    changeMyRank(event, myRank) {
      event.preventDefault();
      this.myRank = myRank;
    },
    changePartnerRank(event, partnerRank) {
      event.preventDefault();
      this.partnerRank = partnerRank;
    },
    changePartnerGender(event, partnerGender) {
      event.preventDefault();
      this.partnerGender = partnerGender;
    },
    changePlayMode(event, playMode) {
      event.preventDefault();
      this.playMode = playMode;
    },
    startVoiceRecord() {
      this.voiceRecordCounter = 10;
      this.voiceRecordChunk = [];
      const self = this;
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          self.mediaRecorder = new MediaRecorder(stream);
          self.mediaRecorder.ondataavailable = (e) => {
            self.voiceRecordChunk.push(e.data);
          };
          self.mediaRecorder.onstop = () => {
            const blob = new Blob(self.voiceRecordChunk, {
              type: "audio/ogg; codecs=opus",
            });
            self.voiceMessage = {
              blob,
              url: URL.createObjectURL(blob),
            };
          };
          const modal = this.$refs.voiceRecordModal;
          UIkit.modal(modal, { container: "#app" }).show();
          self.voiceRecordTimerId = setInterval(() => {
            if (self.voiceRecordCounter == 1) {
              self.stopVoiceRecord();
              return;
            }
            const counter = this.$refs.voiceRecordModalCounter;
            self.voiceRecordCounter--;
            UIkit.slideshow(counter).show(self.voiceRecordCounter - 1);
          }, 1000);
          self.mediaRecorder.start();
        })
        .catch(() => {
          self.voiceRecordError =
            "録音の開始に失敗しました。もう一度お試しください";
        });
    },
    stopVoiceRecord() {
      if (this.mediaRecorder && this.mediaRecorder.state == "recording") {
        this.mediaRecorder.stop();
      }

      if (this.voiceRecordTimerId >= 0) {
        clearInterval(this.voiceRecordTimerId);
      }

      const modal = this.$refs.voiceRecordModal;
      UIkit.modal(modal).hide();

      const counter = this.$refs.voiceRecordModalCounter;
      UIkit.slideshow(counter).show(9);
    },
    playVoiceMessage() {
      const media = new Audio(this.voiceMessage.url);
      media.play();
    },
    deleteVoiceMessage() {
      this.voiceMessage = null;
    },
    async save() {
      try {
        this.loading = true;
        await this.$store.dispatch("updateProfile", {
          nickname: this.nickname,
          icon: this.icon,
          gender: this.gender,
          mainCharactor: this.mainCharactor,
          myRank: this.myRank,
          partnerRank: this.partnerRank,
          partnerGender: this.partnerGender,
          playMode: this.playMode,
          message: this.message,
          voiceMessage: this.voiceMessage,
        });
        this.loading = false;
        UIkit.notification({
          message: "保存しました",
          status: "success",
          pos: "bottom-right",
          timeout: 2000,
        });
      } catch (error) {
        console.error(error);
        UIkit.notification({
          message: "保存に失敗しました。時間をおいてお試しください",
          status: "danger",
          pos: "top-center",
          timeout: 5000,
        });
        this.loading = false;
      }
    },
    async deleteUser() {
      await this.$store.dispatch("deleteUser");
      UIkit.modal(this.$refs.deleteModal).hide();
      this.$router.push("/login");
    },
  },
};
</script>
