import { createApp } from "vue";
import router from "./router";
import store from "./store";
import gtag, { trackRouter } from "vue-gtag-next";
import App from "./App.vue";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import "uikit/dist/css/uikit.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
UIkit.use(Icons);

trackRouter(router, {
  useScreenview: true,
});

const app = createApp(App);
app.use(store);
app.use(router);
app.use(gtag, {
  property: { id: "G-DK2M2T1ZKC" },
});
app.mount("#app");
